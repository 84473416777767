<template>
  <div class="social-share flex gap-l flex-wrap flex-center">
   <!-- the  @share_network_close="emitClosed" is not emitted by social-share working -->
    <ShareNetwork
      @click="emitOpened"
      @share_network_close="emitClosed"
      v-for="network in networks"
      class="flex flex-column flex-center flex-middle gap-m"
      :network="network"
      v-bind="shareParams">
      <icon-halo
        :bg-theme="iconHaloBgTheme"
        :theme="iconHaloIconTheme"
        :icon="getShareIcon(network)"
        :auto-translate="false"
        :title="t('core.share.shareIconTitle', { networkName: ucFirst(network) })"
        :size="iconHaloSize"></icon-halo>
      <span class="text-center c-text">{{ tu("core.share.networks." + network) }}</span>
    </ShareNetwork>
  </div>
</template>

<script>
import SocialShareMixin from "@/client/extensions/mixins/baseComponents/etc/socialShare.js";
import { defineAsyncComponent } from "vue";

export default {
  mixins: [SocialShareMixin],
  components: {
    ShareNetwork: defineAsyncComponent(async () => {
      const { ShareNetwork } = await import("vue-social-sharing");
      return ShareNetwork;
    }),
  },
  inject: ["$saffronComponent"],
  props: {},
  data: function () {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>

export default {
  // notice: I am not sure this works. But this is a dependency, so it is here at least for reference
  inject: ["$saffronComponent"],
  props: {
    networks: {
      type: Array,
      default: () => [
        "facebook",
        "whatsapp",
        "instagram",
        "telegram",
        "email",
        "linkedIn",
        "messenger",
        "pinterest",
        "twitter",
      ],
    },
    url: {
      type: [String, Boolean],
      default: "https://www.icruise.co.il/",
    },
    title: {
      type: [String, Boolean],
      default: false,
    },
    description: {
      type: [String, Boolean],
      default: false,
    },
    media: {
      type: [String, Boolean],
      default: false,
    },
    iconHaloBgTheme: {
      type: String,
      default: "gray-1",
    },
    iconHaloIconTheme: {
      type: String,
      default: "lead",
    },
    iconHaloSize: {
      type: Number,
      default: 2,
    },
    shareIconMap: {
      type: Object,
      default: () => {
        return {
          email: "mail",
        };
      },
    },
  },
  data: function () {
    return {};
  },
  emits: [
    'social-share:open',
    'social-share:opened',
    'social-share:clos',
    'social-share:closed',
  ],
  computed: {
    currentUrl() {
      if (utilities.isSSR()) {
        return this.$route.href;
      }
      return window.location.href;
    },

    currentTitle() {
      // check for meta manager
      let metaTitle = this?.$saffronComponent?.metaManager?.title;
      if (metaTitle && metaTitle !== "") {
        return metaTitle;
      }

      if (this.isSSR()) {
        return "";
      }

      return document.title;
    },
    currentDescription() {
      // check for meta manager
      let metaDesc = this?.$saffronComponent?.metaManager?.description;
      if (metaDesc && metaDesc !== "") {
        return metaDesc;
      }

      if (this.isSSR()) {
        return "";
      }

      return document.querySelector('meta[name="description"]').getAttribute("content");
    },
    currentMedia() {
      // check for meta manager
      let mediaUrl = false;
      if (
        this?.$saffronComponent?.metaManager &&
        this?.$saffronComponent?.metaManager["og:image"]
      ) {
        mediaUrl = this.$saffronComponent.metaManager["og:image"];
      }

      if (mediaUrl && mediaUrl !== "") {
        return mediaUrl;
      }

      if (this.isSSR()) {
        return "";
      }

      return document.querySelector('meta[property="og:image"]').getAttribute("content");
    },
    shareParams() {
      let result = {};
      result.url = this.url ? this.url : this.currentUrl;
      result.title = this.title ? this.title : this.currentTitle;
      result.description = this.description ? this.description : this.currentDescription;
      result.media = this.media ? this.media : this.currentMedia;
      return result;
    },
  },
  methods: {
    emitOpened() {
      this.$emit('social-share:open');
      this.$emit('social-share:opened');
    },
    emitClosed() {
      this.$emit('social-share:clos');
      this.$emit('social-share:closed');
    },
    ucFirst(str) {
      return utilities.ucFirst(str);
    },
    getShareIcon(network) {
      if (this.shareIconMap[network]) {
        return this.shareIconMap[network];
      }

      return `brands-${network}`;
    },
  },
}
